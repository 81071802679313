import "./styles/App.css";
import logo from './assets/logo.png'
import { useState, useEffect, useRef } from "react";
import Particles from "react-tsparticles";

function App() {
	const ref = useRef(null)
	const [width, setWidth] = useState(0)

	useEffect(() => {
		setWidth(ref.current.offsetWidth)
	})

	const particlesInit = (main) => {

	}

	return (
		<div ref={ref} className="App w-screen h-screen flex justify-center items-center flex-col">
			<Particles 
				options={{
					fpsLimit: 60,
					background: {},
					interactivity: {
						events: {
							onClick: { enable: true, mode: "push" },
							onHover: {
								enable: true,
								mode: "repulse",
								parallax: { enable: false, force: 60, smooth: 10 }
							},
							resize: true
						},
						modes: {
							push: { quantity: 4 },
							repulse: { distance: 200, duration: 0.4 }
						}
					},
					particles: {
						color: { value: "#959595" },
						move: {
							direction: "none",
							enable: true,
							outModes: "out",
							random: false,
							speed: 2,
							straight: false
						},
						number: {
							density: {
								enable: true,
								area: 800
							},
							value: 30
						},
						opacity: {
							animation: {
								enable: false,
								speed: 1,
								sync: false,
								startValue: "max",
								count: 1,
								destroy: "min"
							},
							value: {
								min: 0,
								max: 0.5
							}
						},
						shape: {
							type: "circle",
							stroke: {
								width: 0,
								color: '#959595'
							},
							polygon: { nb_sides: 5 },
						},
						size: {
							value: 3,
							random: true,
						},
						line_linked: {
							enable: true,
							distance: 150,
							color: '#959595',
							opacity: 0.4,
							width: 1
						}
					}
				}}
			/>
			<div className="flex flex-col justify-center items-center w-full">
				<img src={logo} className="mb-2 w-[20rem]" />
				<h1 className="text-4xl title">Hi, I'm Nooby</h1>
			</div>
			<div className="flex flex-col items-center md:flex-row mt-5 gap-5">
				<a
					href="https://www.twitch.tv/noobysloth"
					className="uppercase link"
				>
					twitch
				</a>
				{width > 768 &&
					<span>|</span>
				}
				<a
					href="https://discord.gg/hD2DeRm"
					className="uppercase link"
				>
					discord
				</a>
				{width > 768 &&
					<span>|</span>
				}
				<a
					href="https://ko-fi.com/noobysloth"
					className="uppercase link"
				>
					buy me a coffee
				</a>
			</div>
		</div>
	);
}

export default App;
